.toast-container {
  box-shadow: 0px 2px 8px rgba(5, 45, 97, 0.08);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 12px 16px;
}

.toast-container::before {
  content: "";
  width: 4px;
  height: 100%;
  border-radius: 4px 0 0 4px;
  position: absolute;
  left: 0;
  top: 0;
}

.toast-container.success {
  background-image: url("../../assets/checked-outline.svg");
}

.toast-container.error {
  background-image: url("../../assets/close-outline.svg");
}
.toast-container.warning {
  background-image: url("../../assets/initialize-checked-outline.svg");
}

.toast-container.success::before {
  @apply bg-confirm;
}

.toast-container.error::before {
  @apply bg-warning;
}
.toast-container.warning::before {
  @apply bg-orange;
}

/* .toast-container {
}

.Toastify__toast-container .Toastify__toast {
  border: 1px solid #f6f7f9;
  box-shadow: 0px 4px 48px -12px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  background-color: #fff;
  color: #667c99;
  padding: 12px;
}

.Toastify__toast-container .Toastify__toast--error {
}

.Toastify__toast-container .Toastify__toast .mr-2 {
  margin-right: 0 !important;
  margin-top: 0 !important;
}

.Toastify__toast-container .Toastify__progress-bar {
  display: none;
}

.Toastify__toast-container .font-white {
  padding: 0 !important;
} */
