body .css-1okebmr-indicatorSeparator {
  display: none;
}

body .css-1rhbuit-multiValue {
  display: flex;
  align-items: center;
  background-color: transparent;
  @apply border;
  @apply border-gray-300;
  @apply rounded-full;
}

body .css-1rhbuit-multiValue .css-12jo7m5 {
  padding: 4px 8px;
  font-size: 14px;
  @apply text-primary-mainText;
}

body .css-1rhbuit-multiValue .css-xb97g8 svg {
  display: none;
}

body .css-1rhbuit-multiValue .css-xb97g8 {
  width: 18px;
  height: 18px;
  padding: 0;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 8px;
  border-radius: 100%;
  background-image: url("../../assets/close-icon.svg");
  @apply transition;
  cursor: pointer;
}

body .css-1rhbuit-multiValue .css-xb97g8:hover {
  @apply bg-gray-300;
}

body .multiple-select .css-g1d714-ValueContainer {
  padding: 8px 12px;
}

body .css-4ljt47-MenuList {
  padding: 8px;
}

body .css-9gakcf-option,
body .css-yt9ioa-option,
body .css-1n7v3ny-option,
body .css-yt9ioa-option {
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

body .css-1n7v3ny-option {
  transition: 0.15s ease-in-out;
  @apply bg-gray-100;
}

body .css-ke0dz-control, body .css-ozhs9e-control {
  min-height: 39px;
}

.css-26l3qy-menu {
  animation: fadeIn 0.2s ease-in-out;
}

body .porta-react-select__menu-list  {
  padding: 8px;
}

body .porta-react-select__placeholder {
  @apply text-gray-400;
}

body .invalid-field .porta-react-select__control {
  @apply border-red;
}

body .porta-react-select__indicator {
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  padding: 0;
  background-position: center;
  margin-right: 6px;
}

body .porta-react-select__dropdown-indicator {
  background-image: url("../../assets/arrow-bottom.svg");
  @apply transition;
}

body .porta-react-select__clear-indicator {
  background-image: url("../../assets/close-icon.svg");
}

body .porta-react-select__indicator svg {
  display: none;
}

body .porta-react-select__control--menu-is-open .porta-react-select__indicator {
  transform: rotate(180deg);
}

body .porta-react-select__control {
  @apply border-gray-300;
}

body .porta-react-select__control:hover {
  @apply border-gray-400;
}

body .porta-react-select__control--is-focused, body .porta-react-select__control--is-focused:hover {
  @apply border-primary;
}



@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
